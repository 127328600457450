.slick-prev:before,
.slick-next:before{
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  border-left: 1px solid rgba(0,0,0,.25);
  border-top: 1px solid rgba(0,0,0,.25);
}
.slick-prev:before{
  transform: rotate(-45deg);
}
.slick-next:before{
  transform: rotate(135deg);
}