.wrap{
  width: 280px;
  .smallSlide{
    margin: 0 22px;
  }
}
.bigSlick{
  img{
    height: 280px;
    width: 280px;
    margin-bottom: 22px;
    // background: #5f9ea0;
    // color: #fff;
    // font-size: 36px;
    // line-height: 280px;
    position: relative;
    text-align: center;
  }
}
.smallSlick{
  img{
    width: 64px;
    height: 64px;
    margin: 0 7.5px;
    // background: #5f9ea0;
    // color: #fff;
    // font-size: 36px;
    // line-height: 64px;
    text-align: center;
    cursor: pointer;
  }
}
