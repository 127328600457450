@import './index.module.scss';
.content{
  min-height:790px;
  min-width:800px;
  .title{
    margin: -10px 0 10px -16px;
    background: #FFFFFF;
    .title_head{
      font-size:14px;
      font-weight:400;
      padding:20px 0 0 20px;
    }
    .title_info{
      padding:20px;
      font-size:20px;
      font-weight:600;
    }
    button{
      @extend .right;
    }
  }
  .searchCondition{
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    >div{
      display: inline-block;
      &.name{
        margin-right: 10px;
        line-height: 32px;
        font-size: 16px;
      }
      &.flex1{
        display: flex;
        flex: 1;
        align-items: center;
        .select{
          flex: 1;
          margin-right: 30px;
        }
        .search{
          @extend .select;
        }
        .button_style{
          margin:0 10px;
        }
        .rangePicker1{
          min-width: 120px;
        }
        .rangePicker2{
          min-width: 120px;
          margin-right:10px;
        }
      }
      &.search_info{
        display: inline-block;
        width: 200px;
        margin-right:10px;
      }
    }
  }
  .table{
    .row{
      .productImage{
        width: 64px;
        height: 64px;
      }
      .productName{
        max-width: 200px;
      }
      .delete{
        color: #1890ff;
        &:hover{
          color: #40a9ff;
        }
      }
    }
  }
}
.time_info{
  color: #d8d8d8;
  padding:0 5px;
}
.setting_info{
  font-size:16px;
  font-weight:600; 
}
.superior_partner{
  font-size:14px;
  font-weight:600;
  margin-top:24px;
  .input_style{
    margin:0 10px;
  }
}
.tishi_info{
  color:#9B9B9B;
  margin:21px 0;
}