.content{
  height: 80%;
  display: flex;
  align-items: center;
  min-height: 500px;
  .bgWrap{
    flex: 0 0 62.5%;
    width: 62.5%;
    padding-right: 152px;
    zoom: 1;
    .bg{
      float: right;
      width: 100%;
      max-width: 430px;
      height: 360px;
      background-position: 50% 50%;
      background-size: contain;
      background-repeat: no-repeat;
      &.bg404{
        background-image: url('../images/404.svg');
      }
      &.bg403{
        background-image: url('../images/403.svg');
      }
      &.bg500{
        background-image: url('../images/500.svg');
      }
    }
  }
  .title{
    margin-bottom: 24px;
    color: #434e59;
    font-weight: 600;
    font-size: 72px;
    line-height: 72px;
  }
  .text{
    margin-bottom: 16px;
    color: rgba(0,0,0,.45);
    font-size: 20px;
    line-height: 28px;
  }
}