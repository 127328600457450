@import './index.module.scss';
.wrap{
  margin-left: 30px;
  .title{
    max-width: 480px;
    margin-bottom: 10px;
    line-height: 33px;
    font-size: 24px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .description{
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 22px;
  }
  .attribute{
    margin-bottom: 20px;
    .name{
      margin-bottom: 10px;
      color: #9b9b9b;
    }
    .formatItemWrap{
      max-width: 460px;
      .formItem{
        margin-bottom: 8px;
      }
    }
    .picture{
      margin-right: 10px;
      cursor: pointer;
      img{
        width: 64px;
        height: 64px;
        border:1px solid #D9D9D9;
      }
      div{
        margin-top: 5px;
        line-height: 20px;
        text-align: center;
      }
    }
    .formatValue{
      min-width:79px;
      height:30px;
      margin-right: 10px;
      line-height: 30px;
      font-size: 16px;
      border:1px solid #D9D9D9;
      text-align: center;
      cursor: pointer;
      padding: 0 5px;
    }
    .time{
      margin-top: 5px;
      line-height:22px;
      font-size:16px;
    }
  }
}