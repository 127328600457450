@import './index.module.scss';
.content{
  .table{
      overflow: auto!important;
      .userId{
        display: inline-block;
        font-size:20px;
        margin-left:5px;
        font-weight:600;
        color:rgba(0,0,0,0.85);
      }
      .tableTitle{
        font-weight:500;
        color:rgba(0,0,0,0.85);
      }
      .detail{
        position: relative;
        color: #1890ff;
        cursor: pointer;
        &:after{
          content: '';
          position: absolute;
          right: -15px;
          top: 5px;
          width: 6px;
          height: 6px;
          border-left: 1px solid #1890ff;
          border-top: 1px solid #1890ff;
          transform: rotate(-135deg);
        }
        &.active{
          &:after{
            top: 8px;
            transform: rotate(45deg);
          }
        }
      }
      .detailName{
        font-size: 14px;
        font-weight: 500;
        color: #000;
        span{
          margin-left: 13px;
          color:rgba(0,0,0,0.65);
        }
        .icon{
          margin-left: 18px;
          cursor: pointer;
        }
      }
      .productImage{
        width: 64px;
        height: 64px;
      }
    }
  .userInfo1{
    display: inline-block;
    margin: 5px 5px 5px 0;
    .linkInfo1{
      color:rgba(0,0,0,0.45);
    }
  }
  .userInfo2{
    display: inline-block;
    margin:5px;
  }
  .userDetail{
    margin:20px 0;
    display: flex;
    position: relative;
    .imgInfo{
      display: inline-block;
      margin-right: 10px;
      .userImg{
        width: 50px;
        height: 50px;
      }
      .iconImg{
        font-size: 50px;
      }
    }
    .userOperation{
      position: absolute;
      right:0;
      .buttonStyle{
        margin:5px;
      }
      .userStatus{
        display: inline-block;
        float: right;
        margin-left:40px;
      }
    }
    
  }
  
  .productInfoWrap,
  .productPicture{
    width: 790px;
    margin: 0 auto;
  }
  .productPicture{
    margin-top: 34px;
  }
  .iconStyle{
    margin:0 5px;
  }
}
.clearfix:after{
  content: '';
  display: block;
  clear: both;
}
.screenInfo{
  margin-bottom: 10px;
  .searchInfo{
    margin:10px;
    display: inline-block;
    .search1{
      width:328px;
    }
    .search2{
      width:200px;
    }
    .lineStyle{
      margin:10px;
      color:#D8D8D8;
    }
  }
  .radoiInfo1{
    margin:10px 0 10px 10px;
    display: inline-block;
  }
  .radoiInfo2{
    margin:10px 0 10px 10px;
    .radoiDetail{
      margin-right: 20px;
      display: inline-block;
    }
    .amountItem{
      display: inline-block;
      margin:10px 100px 10px 0;
      .amountInfo{
        font-size:16px;
        font-weight:400;
        color:rgba(0,0,0,0.85);
      }
    }
    .searchDetail{
      margin:10px 0;
      .inputId{
        width:200px;
        margin-right:10px;
      }
      .selectStatus{
        width:130px;
        margin-right: 10px;
      }
      .timeStyle{
        display: inline-block;
        margin:10px 10px 10px 0;
        .time_info{
          color:#D8D8D8;
          margin:0 5px;
        }
      }
    }
  }
  
}
.txidStyle{
  width:250px;
  text-overflow:ellipsis;
  overflow:hidden;
  white-space:nowrap;
}
.partner1{
  display: flex;
  margin:10px;
  .partnerInfo{
    flex:1;
  }
}
.partner2{
  display: flex;
  margin:10px;
  background: #F5F5F5;
  .partnerInfo2{
    line-height: 32px;
    flex:1;
    .partnerSelect{
      width: 200px;
    }
  }
}
.subordinate{
  margin:20px 0 10px 10px;
}

