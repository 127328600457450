.layout{
  min-height: 100vh !important;
  .siderStyle{
    // overflow:auto;
    position:fixed;
    height:100%;
    left: 0;
    z-index: 5;
  }
  .logoWrap{
    height: 64px;
    line-height: 64px;
    text-align: center;
    color: #333;
    font-weight: bold;
    .logo{
      width: 200px;
      .imgWarp{
        float: left;
        border-radius:50%; 
        height: 32px; 
        width: 32px;
        margin:16px 10px 0 20px;
        overflow:hidden;
        .logoImage{
          height: 32px;
          margin-top:-35px;
        }
      }
    }
    .logoTitle{
      text-align: left;
      width: 200px;
      height: 64px;
      margin-left: 10px;
    }
  }
  .sectionRight{
    overflow-x: auto !important;
    position: relative;
    // padding-left: 200px;
    padding-bottom:48px;
    .footer{
      text-align: center;
      position: absolute;
      bottom:0;
      right:0;
      left:0;
    }
  }
  .header{
    text-align: center;
    padding: 0;
    background: #e6eaed;
    overflow:auto;
    position:fixed;
    top: 0;
    right:0;
    left:0;
    z-index: 4;
    // content: '';
    // display: block;
    // clear: both;
  }
  .contentWrap{
    margin:80px 16px 20px 15px;
    max-width: 1688px;
    min-width: 968px;
    min-height:790px;
    .content{
      padding: 40px 30px 65px;
      background: #ffffff;
    }
  }
  .siderBg {
    background: #eee;
  }
}
.hide{
  display: none;
}
