// @import style from '../../common/scss/Index.module.scss';
@import '../../common/scss/index.module.scss';

.nav{
  @extend .right;
  margin-right: 16px;
  .email{
    color: #aabcd3;
    font-size: 20px;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 30px;
  }
  .userStyle{
    display: inline-block;
    .user{
      display: inline-block;
      line-height: 22px;
      vertical-align: middle;
      margin-right: 10px;
      .role{
        font-size: 14px;
      }
      .nickname{
        font-size: 12px;
      }
    }
  }
  .avatar{
    margin-right: 10px;
  }
}
