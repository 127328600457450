// @import '~antd/lib/style/themes/default.less';
@import './index.module.scss';

.iconGroup {
  i {
    margin-left: 16px;
    // @extend .text-color-secondary;
    cursor: pointer;
    transition: color 0.32s;
    &:hover {
      // @extend .text-color;
    }
  }
}

.rankingList {
  margin: 25px 0 0;
  padding: 0;
  list-style: none;
  li {
    @extend .clearfix;
    display: flex;
    align-items: center;
    margin-top: 16px;
    span {
      // color: @text-color;
      font-size: 14px;
      line-height: 22px;
    }
    .rankingItemNumber {
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-top: 1.5px;
      margin-right: 16px;
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      // background-color: @background-color-base;
      border-radius: 20px;
      &.active {
        color: #fff;
        background-color: #314659;
      }
    }
    .rankingItemTitle {
      flex: 1;
      margin-right: 8px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.salesExtra {
  display: inline-block;
  margin-right: 24px;
  span {
    margin-left: 24px;
    cursor: pointer;
    // @extend .text-color;
    &:hover {
      color: #1890ff;
      // @extend .primary-color;
    }
    &.currentDate {
      color: #1890ff;
      // @extend .primary-color;
    }
  }
}

.salesCard {
  .salesBar {
    padding: 0 0 32px 32px;
  }
  .salesRank {
    padding: 0 32px 32px 72px;
  }
  :global {
    .ant-tabs-bar {
      padding-left: 16px;
      .ant-tabs-nav .ant-tabs-tab {
        padding-top: 16px;
        padding-bottom: 14px;
        line-height: 24px;
      }
    }
    .ant-tabs-extra-content {
      padding-right: 24px;
      line-height: 55px;
    }
    .ant-card-head {
      position: relative;
    }
    .ant-card-head-title {
      align-items: normal;
    }
  }
}
.sales{
  margin-top: 24px;
}
