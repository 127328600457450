.content{
  margin:-10px -16px 20px -16px;
  .orderDetail{
    background: #ffffff;
    padding:16px;
    display: flex;
    .detailInfo{
      flex:2;
      display: inline-block;
      .toOrder{
        display: inline-block;
        margin: 5px 5px 5px 0;
        .linkInfo1{
          color:rgba(0,0,0,0.45);
        }
      }
      .userInfo2{
        display: inline-block;
        margin:5px;
      }
      .title{
        margin-left:32px;
        margin-top:20px;
        display: flex;
        .orderId{
          display: inline-block;
          font-size:20px;
          font-weight: 600;
          margin-left:16px;
        }
      }
      .orderInfo{
        margin:18px 0 23px 76px;
        .orderItem{
          margin-bottom:8px;
          display: flex;
          .orderTitle{
            flex:1;
            display: inline-block;
            overflow: hidden;
            white-space: nowrap; 
            text-overflow: ellipsis;
          }
        }
      }
      .productInfoWrap{
        margin:20px
      }
      
    }
    .rightInfo{
      text-align: right;
      flex:1;
      display: inline-block;
      margin-top:50px;
      margin-right: 10px;
      position: relative;
      .buttonStyle{
        margin:5px;
      }
      .itemStyle{
        margin-top:15px;
        .item1{
          margin-right: 145px;
          // position: absolute;
          // left:5px;
          display: inline-block;
          text-align: center;
          .status1{
            font-size:20px;
            color:#F5A623;
          }
          .status2{
            font-size:20px;
            color:#7ED321;
          }
          .status3{
            font-size:20px;
            color:#D0021B;
          }
        }
        .item2{
          display: inline-block;
          // position: absolute;
          // right:5px;
          text-align: center;
          .money{
            font-size: 20px;
            color:rgba(0,0,0,0.85);
          }
        }
      }
    }
  }
  .table{
    margin:20px;
    background: #ffffff;
    .tableTitle{
      font-size:16px;
      font-weight:500;
      color:rgba(0,0,0,0.85);
      padding:16px 0 0 32px;
    }
    .tableStyle{
      margin:32px;
    }
  }
  
  
}
.clearfix:after{
  content: '';
  display: block;
  clear: both;
}
.left{
  float: left;
}
.right{
  float: right;
}
.productImage{
  width: 64px;
  height: 64px;
}
.tabStyle{
  background: #ffffff;
  margin:20px!important;
  padding:10px;
}