@import './index.module.scss';
.content{
  min-height:790px;
  min-width:800px;
  .title{
    margin: -10px 0 10px -16px;
    background: #FFFFFF;
    .title_head{
      font-size:14px;
      font-weight:400;
      padding:20px 0 0 20px;
    }
    .title_info{
      padding:20px;
      font-size:20px;
      font-weight:600;
    }
    button{
      @extend .right;
    }
  }
  .tableTitle{
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 500;
    color: rgba(0,0,0,0.85);
    span{
      margin-left: 20px;
      font-size:14px;
      color:rgba(0,0,0,0.65);
      line-height:20px;
    }
  }


  .table{
    margin-top: 20px;
    .sticky{
      margin-left: 32px;
      color: #1890ff;
      cursor: pointer;
    }
    .update{
      color: #1890ff;
      cursor: pointer;
    }
  }
}
.modal{
  width: 996px !important;
  .themeName{
    width: 224px;
    margin-top: 4px;
  }
  .picItem{
    display: inline-block;
    width: 12.5%;
    .name{
      margin-top: 20px;
      margin-bottom: 12px;
    }
    .accept{
      margin-top: 8px;
      color: rgba(0,0,0,0.45);
    }
  }
  .optionsWrap{
    margin-top: 12px;
    .options{
      display: inline-block;
      width: 224px;
      height: 91px;
      margin-right: 16px;
      margin-top: 12px;
      &:nth-child(4n) {
        margin-right: 0;
      }
    }
  }
}
