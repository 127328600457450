.container{
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-height: 600px;
  background-image: url("../images/background.svg");
  background-repeat: no-repeat;
  background-position: center 110px;
  background-size: 100%;
  background-color: #f0f2f5;
  overflow: auto;
  .logoWrap{
    margin: 80px 0 40px 0;
    text-align: center;
    .logo{
      vertical-align: middle;
      border-radius:50%;
      display: inline-block;
      width: 60px;
      height: 60px;
      overflow: hidden;
      .logoImg{
        width: 60px;
      }
    }
    .title{
      font-size: 33px;
      vertical-align: middle;
    }
    .description{
      margin-left:20px;
      height: 60px;
      line-height: 60px;
      vertical-align: middle;
      display: inline-block;
      font-size: 30px;
      font-weight:400;
      color: #000000;
      display: inline-block;
    }
  }
  .wrap{
    width: 370px;
    margin: 0 auto;
  }
  .footer{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding-bottom: 24px;
    text-align: center;
    font-size:12px;
    color:rgba(0,0,0,0.45);
  }
}