@import './index.module.scss';

.content {
  .leftStyles {
    margin-left: 30px;
    margin-bottom: 30px;

    .title {
      display: flex;
      margin-bottom: 41px;

      button {
        @extend .right;
      }
    }

    .wholeStyle {
      display: flex;
      justify-content: space-between;

      .leftStyle {
        display: flex;

        .infoStyle {
          float: right;
          margin: auto;
          margin-left: 20px;

          .nameStyle {
            font-size: 18px;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.85);
            line-height: 25px;

            .context1 {
              display: flex;
              float: right;
              margin-left: 8px;
              width: 70px;
              height: 19px;
              background: rgba(39, 67, 102, 1);
              border-radius: 12px;

              .contextText1 {
                margin: auto;
                height: 17px;
                font-size: 12px;
                color: rgba(255, 255, 255, 1);
                line-height: 17px;
              }
            }

            .context2 {
              display: flex;
              float: right;
              margin-left: 8px;
              width: 70px;
              height: 19px;
              background: rgba(255, 255, 255, 1);
              border-radius: 12px;

              .contextText2 {
                margin: auto;
                height: 17px;
                font-size: 12px;
                color: rgba(155, 155, 155, 1);
                line-height: 17px;
              }
            }
          }
        }
      }
    }

    .rightStyle {
      float: right;
      margin-top: 14px;

      .lastTime {
        display: flex;
        font-size: 14px;
        font-weight: 300;
        color: rgba(109, 114, 120, 1);
        line-height: 22px;
      }

      .lastIP {
        display: flex;
        float: right;
        font-size: 14px;
        font-weight: 300;
        color: rgba(109, 114, 120, 1);
        line-height: 22px;
      }
    }
  }

  .API {
    font-size: 14px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 24px;
    margin-bottom: 6px;
  }

  .API_KeyStyle {
    display: flex;
    margin-bottom: 6px;
    .copyStyle{
      margin-left: 12px;
    }
    .regenerate{
      float: right;
      margin-left: 18px;
      font-size:14px;
      font-weight:400;
      line-height:20px;
      cursor: pointer;
      color: #1890ff;
      &:hover{
        color: #40a9ff;
      }
    }
  }

  .warningStyle {
    font-size: 12px;
    font-weight: 400;
    color: #E02020;
    line-height: 22px;
    margin-bottom: 45px;
  }

  .pawStyle {
    display: flex;
    justify-content: space-between;

    .passWord {
      font-size: 14px;
      font-weight: 400;
      color: #4A4A4A;
      line-height: 20px;
      .introduce {
        margin-top: 5px;
        font-size: 14px;
        font-weight: 400;
        color: #9B9B9B;
        line-height: 20px;
        white-space:normal;
        word-break:break-all;
      }
    }

    .bottommodify {
      float: right;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      margin-top: 20px;
      margin-right: 25px;
    }

    .modify {
      float: right;
      min-width: 80px;
      text-align: right;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      margin-top: 25px;
      margin-right: 20px;
      cursor: pointer;
      color: #1890ff;
      &:hover{
        color: #40a9ff;
      }
    }
  }
  .reconnect{
    cursor: pointer;
    display: inline-block;
    margin-left: 20px;
    vertical-align: middle;
    color: #1890ff;
    &:hover{
      color: #40a9ff;
    }
  }
}
