// 公共样式文件
.left{
  float: left;
}
.right{
  float: right;
}
.clearfix:after{
  content: '';
  display: block;
  clear: both;
}
.hide{
  display: none !important;
}

.content{
  .title{
    font-size: 30px;
    color: #274366;
  }
  .breadCrumb{
    margin-top: 20px;
    margin-bottom: 50px;
    span{
      font-size: 16px;
    }
  }
  .breadCrumbMin{
    margin-top: 15px;
    margin-bottom: 10px;
    span{
      font-size: 16px;
    }
  }
}

// 注册
.signupBtn{
  width: 176px;
}
.getCode{
  min-width: 112px;
}

// 登录
.geetest{
  margin-top: 40px;
  margin-bottom: 10px;
}

//上传
.App {
  font-family: sans-serif;
  text-align: center;
}
.modal{
  display: flex;
  .box {
    display: flex;
    height: 394px;
    width: 839px;
    flex-wrap: wrap;
    .item {
      display: flex;
      height: 180px;
      margin-left: 14px;
      position: relative;
      .imageStyle{
        width: 150px;
        height: 150px;
        cursor: pointer;
      }
      .textStyle{
        margin: auto;
        position: absolute;
        bottom: 0px;
      }
    }
  }
}

.upload{
  margin-bottom: -110;
}
.pageNum{
  text-align: center;
  margin-top: 20px;
}
.text {
  color: #18181F;
}
.selected{
  border: 1px solid #1890ff;
}
